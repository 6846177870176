import React from 'react';
import '../../pages/Project/ProjectPage.css'
import { Link } from 'react-router-dom';
import { ButtomGet } from '../ButtomGet/ButtomGet';

/* Multi idioma */
import { FormattedMessage } from 'react-intl';

/* Swiper */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

/* Img */
const proyectImg = require.context('../../img', true);

const Project = () => {
    return (
        <section className="proyectos" id="proyectos">
            <h2 className="heading">
                <FormattedMessage
                    id='projects'
                    defaultMessage='Projects'
                />
            </h2>
            <div className="proyect-site" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <Swiper
                    spaceBetween={30}
                    loop={true}
                    grabCursor={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                    }}
                    className='proyectos-slider mySwiper'
                >
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./proyecto-14.jpg`)}
                            alt='proyectos'

                        />
                        <div className="content">
                            <h3>Clasher's Den</h3>
                            <p>
                             An Discord Automation AI Bot
                            </p>
                            <p className="tecnologias">
                                HTML5
                                <span>-</span> CSS3
                                <span>-</span> JavaScript
                                <span>-</span> jQuery
                                <span>-</span> Bootstrap
                            </p>
                            <a href="https://abhaypro.in/discord-bot" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Demo</span></a>
                            <a href="https://www.instagram.com/abhay_clasher/" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Contact</a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./proyecto-7.jpg`)}
                            alt='proyectos'
                        />
                        <div className="content">
                            <h3>IND Community</h3>
                            <p>
                            portfolio site fo IND Community
                            </p>
                            <p className="tecnologias">
                                HTML5
                                <span>-</span> CSS3
                                <span>-</span> JavaScript
                            </p>
                            <a href="https://abhaypro.in/" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Demo</span></a>
                            <a href="https://www.instagram.com/abhay_clasher/" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Contact</a>
                        </div>
                 
                    </SwiperSlide>
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./proyecto-12.jpg`)}
                            alt='proyectos'
                        />
                        <div className="content">
                            <h3>EveryRunning</h3>
                            <p>
                                Website shoes
                            </p>
                            <p className="tecnologias">
                                HTML5
                                <span>-</span> CSS3
                                <span>-</span> JavaScript
                                <span>-</span> Bootstrap
                            </p>
                            <a href="" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Comming Soon</span></a>
                            <a href="https://www.instagram.com/abhay_clasher/" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Contact</a>
                        </div>
                
                   
                    </SwiperSlide>
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./proyecto-8.jpg`)}
                            alt='proyectos'
                        />
                        <div className="content">
                            <h3>Anant Store</h3>
                            <p>
                                Ecommerce
                            </p>
                            <p className="tecnologias">
                                HTML5
                                <span>-</span> CSS3
                                <span>-</span> Sass
                                <span>-</span> JavaScript
                            </p>
                            <a href="" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Comming Soon</span></a>
                            <a href="https://www.instagram.com/abhay_clasher/" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Contact</a>
                        </div>
                   
                   
                    </SwiperSlide>
                    <SwiperSlide className='caja'>
                        <img
                            src={proyectImg(`./proyecto-11.jpg`)}
                            alt='proyectos'
                        />
                        <div className="content">
                            <h3>Plus Anime</h3>
                            <p>
                                Anime website
                            </p>
                            <p className="tecnologias">
                                HTML5
                                <span>-</span> CSS3
                                <span>-</span> JavaScript
                            </p>
                            <a href="" className="custom-btn btn" target="_blank" rel="noopener noreferrer"><span>Comming Soon</span></a>
                            <a href="https://www.instagram.com/abhay_clasher/" className="custom-btn btn-codigo" target="_blank" rel="noopener noreferrer">Contact</a>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className="swiper-pagination"></div>
            </div>
            {/* <Link className="custom-btn btn-codigo portafolio-btn" to="/project">
                <FormattedMessage
                    id='btn-more-projects'
                    defaultMessage='More projects'
                />
            </Link> */}
            <div className='portafolio-btn'>
                <Link to="/project">
                    <ButtomGet/>
                </Link>
            </div>
        </section>

    )
};
export default React.memo(Project);